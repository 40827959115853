import classes from "./Input.module.css";
import { CopyTextLink } from "../Parts/CopyImageLink";
import React, { useState, useEffect } from "react";
import downloadIcon from "../../images/download_icon.png";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../services/firebase.config";

export const Input = ({ roomID, item }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [inputText, setInputText] = useState("");
  const [composing, setComposing] = useState(false);

  useEffect(() => {
    if (!composing) {
      setInputText(item.text);
    }
  }, [item, inputText]);

  const handleSelectItem = (url) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(url)
        ? prevSelectedItems.filter((item) => item !== url)
        : [...prevSelectedItems, url]
    );
  };

  const allClear = () => {
    setSelectedItems([]);
  };

  const allDownload = () => {
    downloadFiles(selectedItems);
    allClear();
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);

    if (!composing) {
      updateDoc(doc(db, "rooms", item.id), {
        text: event.target.value,
      });
    }
  };

  const handleCompositionStart = (event) => {
    setComposing(true);
  };

  const handleCompositionEnd = async (event) => {
    setComposing(false);
    setInputText(event.target.value);
    updateDoc(doc(db, "rooms", item.id), {
      text: event.target.value,
    });
  };

  function getFileType(url) {
    // URLのクエリを除去
    const urlObject = new URL(url);
    const cleanUrl = urlObject.origin + urlObject.pathname;

    // 拡張子を取得
    const extension = cleanUrl.split(".").pop().toLowerCase();
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "webp",
      "tiff",
      "svg",
    ];
    const videoExtensions = [
      "mp4",
      "mov",
      "wmv",
      "flv",
      "avi",
      "mkv",
      "webm",
      "mpeg",
      "mpg",
      "m4v",
    ];

    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else {
      return "unknown";
    }
  }

  function downloadFile(url, type) {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadUrl;

        a.download = generateFileName();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(downloadUrl);
        return `${type} from ${url} downloaded successfully.`;
      })
      .catch((error) => `Error downloading file from ${url}: ${error}`);
  }

  function generateFileName() {
    // 現在の日時を取得
    const now = new Date();

    // 年、月、日、時、分、秒、ミリ秒を取得してフォーマット
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // 月は0から始まるため+1
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    // ファイル名を生成
    const fileName = `RK+${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}.png`;

    return fileName;
  }

  function downloadFiles(urls) {
    const downloadPromises = urls.map((url) => {
      const fileType = getFileType(url);
      if (fileType !== "unknown") {
        return downloadFile(url, fileType);
      } else {
        return Promise.resolve(`Unknown file type for URL: ${url}`);
      }
    });

    Promise.all(downloadPromises).then((results) => {
      results.forEach((result) => console.log(result));
    });
  }
  const ImageChild = (props) => {
    const imageLists = props.srcArray.map((src, index) => {
      return (
        <div
          className={`${classes.gridItem}`}
          key={index}
          onClick={() => handleSelectItem(src)}
        >
          <img src={src} alt="" />
          {selectedItems.includes(src) && (
            <div className={`${classes.checkmark}`}>✓</div>
          )}
        </div>
      );
    });
    return <>{imageLists}</>;
  };

  const VideoChild = (props) => {
    const videoLists = props.srcArray.map((srcURL, i) => {
      return (
        <div className={`${classes.gridItem}`}>
          <video src={srcURL}></video>
        </div>
      );
    });
    return <>{videoLists}</>;
  };

  return (
    <div className={`${classes.root}`}>
      <div className={`${classes.container}`}>
        <div className={`${classes.slime1}`}></div>
        <div className={`${classes.slime2}`}></div>
        <div className={`${classes.slime3}`}></div>
        <div className={`${classes.slime4}`}></div>
        <div className={`${classes.slime5}`}></div>
        {selectedItems.length > 0 && (
          <div className={`${classes.selectionBanner}`}>
            <p>{selectedItems.length} 枚選択しています</p>
            <button
              className={`${classes.downloadButton}`}
              onClick={allDownload}
            >
              <img
                src={downloadIcon}
                alt="ダウンロード"
                class="button-icon"
              ></img>
              ダウンロードする
            </button>
          </div>
        )}

        <div className={`${classes.panel}`}>
          <div className={`${classes.titleContainer}`}>
            <div className={`${classes.title}`}>テキスト</div>
          </div>
          <div className={`${classes.textContainer}`}>
            <div className={`${classes.text}`}>
              {item.enabled_two_way_sync ? (
                <textarea
                  type="text"
                  value={inputText}
                  onChange={handleInputChange}
                  onCompositionStart={handleCompositionStart}
                  onCompositionEnd={handleCompositionEnd}
                  className={`${classes.inputAsText}`}
                ></textarea>
              ) : (
                <>{item.text}</>
              )}
            </div>
            <div className={`${classes.floatingButton}`}>
              <CopyTextLink text={item.text} />
            </div>
          </div>
        </div>

        <div className={`${classes.panel}`}>
          <div className={`${classes.titleContainer}`}>
            <div className={`${classes.title}`}>画像・動画</div>
          </div>

          <div className={`${classes.gridGlass}`}>
            <div className={`${classes.gridContainer}`}>
              <ImageChild srcArray={item.image_urls} />
              <VideoChild srcArray={item.video_urls} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Input;
