import axios from "axios";
import { Button } from "antd";
import React, { useState } from "react";
import classes from "./CopyImageLink.module.css";

export const CopyTextLink = ({ text }) => {
  const [showToast, setShowToast] = useState(false);

  const copyToClipboard = async () => {
    setShowToast(true);
    await global.navigator.clipboard.writeText(text);
    // 2秒後にトーストを非表示にする
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={copyToClipboard}
        style={{ background: "#E2C417", borderColor: "#E2C417" }}
        size="large"
      >
        すべてコピーする　　　　　　　　　
      </Button>

      {/* トースト表示 */}
      {showToast && <div className={`${classes.toast}`}>コピーしました</div>}
    </div>
  );
};

export const CopyImageLink = ({ url }) => {
  const copyImageToClipboard = async () => {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });
      const blob = response.data;

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = await createImageBitmap(blob);
      canvas.width = image.width;
      canvas.height = image.height;
      if (ctx) {
        ctx.drawImage(image, 0, 0);
      }

      canvas.toBlob(async (newBlob) => {
        if (newBlob) {
          const clipboardItem = new ClipboardItem({
            [newBlob.type]: newBlob,
          });
          await global.navigator.clipboard.write([clipboardItem]);
        }
      }, blob.type);
    } catch (err) {
      console.error("Failed to copy on clipboard", err);
    }
  };
  return (
    <div>
      <Button type="default" onClick={copyImageToClipboard}>
        コピー
      </Button>
    </div>
  );
};
