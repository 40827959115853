// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA0nyHp_RLASwxj4yvvEHeWZvRYgG2q1w0",
  authDomain: "tkg-pj.firebaseapp.com",
  projectId: "tkg-pj",
  storageBucket: "tkg-pj.appspot.com",
  messagingSenderId: "83077179201",
  appId: "1:83077179201:web:d1f65ae1d1fb4a6e51e3c0",
  measurementId: "G-D82CPN2Y5Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { analytics, auth, db };
