import Input from "../Input/Input";
import { QRCodeCanvas } from "qrcode.react";
import classes from "./Home.module.css";
import qrImage01 from "../../images/qr_image_01.jpg";
import qrImage02 from "../../images/qr_image_02.jpg";
import qrImage03 from "../../images/qr_image_03.jpg";
import React, { useState, useEffect } from "react";

export const Home = ({ roomID, item }) => {
  const images = [qrImage01, qrImage02, qrImage03];

  const [randomImage, setRandomImage] = useState(images[0]);
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // 空の依存配列で初回レンダー時のみ実行

  return (
    <div>
      {item && <Input roomID={roomID} item={item} />}
      {!item && (
        <div className={`${classes.root}`}>
          <div className={`${classes.slime1}`}></div>
          <div className={`${classes.slime2}`}></div>
          <div className={`${classes.slime3}`}></div>
          <div className={`${classes.slime4}`}></div>

          <div className={`${classes.home}`}>
            <div className={classes.description}>
              <div className={classes.title}>
                <div className={classes.textBlock}>
                  スマートフォンで二次元バーコードをスキャンしてください
                </div>
              </div>
            </div>

            <div className={`${classes.qr}`}>
              <QRCodeCanvas
                value={
                  "https://rakudrop.page.link/?link=https://rakudrop.com/&isi=6504337976&ibi=com.bitta.rakudrop&booking_id=" +
                  roomID
                }
                size={200}
              />
              <div className={`${classes.qrOnImage}`}>
                <img src={randomImage} alt="qrImage01" width={50} height={50} />
              </div>
            </div>
            <div className={classes.label}>※このコードは一度限り有効です</div>
            <div className={`${classes.bottomDesc}`}>
              RakuDrop ｜ラクドロ - iPhoneからパソコンへ画像共有できるアプリ -
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
